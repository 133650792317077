/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum Product {
    LEASE_MANAGER = 'leaseManager',
    LEASE_REGISTER = 'leaseRegister',
    CRITICAL_DATES = 'criticalDates',
    AUDIT = 'audit',
    LEASE_CAPITALIZER = 'leaseCapitalizer',
}
