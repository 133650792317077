import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { toValue } from '@vueuse/core'
import type { MaybeRefOrGetter } from '@vueuse/core'
import { useGoogleMapsImportLibrary } from './import-library'

export type Markers<T = any> = Omit<
  google.maps.marker.AdvancedMarkerElementOptions,
  'map'
> & { data?: T }

type InfoWindowArgs<T = any> = {
  marker: google.maps.marker.AdvancedMarkerElementOptions
  data?: T
}

export interface UseMarkersOptions<T = any> {
  markerClusterer?: boolean
  infoWindow?: (
    args: InfoWindowArgs<T>,
  ) => google.maps.InfoWindowOptions | false
}

export const useGetLatitudeLongitude = (
  address: Ref<string | undefined | null>,
) => {
  const GeocodingLibrary = useGoogleMapsImportLibrary('geocoding')
  const results = ref<google.maps.GeocoderResult[]>([])
  watch(
    () => [GeocodingLibrary.value, address.value] as const,
    async ([_GeocodingLibrary, _address]) => {
      if (!_GeocodingLibrary || !_address) return

      const geocoder = new _GeocodingLibrary.Geocoder()
      const geoLoc = await geocoder.geocode({ address: _address })
      if (geoLoc.results) {
        results.value = geoLoc.results
      }
    },
    {
      flush: 'post',
    },
  )
  return results
}

export const useGoogleMapsMarkers = <T = any>(
  map: MaybeRefOrGetter<google.maps.Map | undefined>,
  markers: MaybeRefOrGetter<Markers<T>[]>,
  options: MaybeRefOrGetter<UseMarkersOptions<T>> = {},
) => {
  const MarkerLibrary = useGoogleMapsImportLibrary('marker')
  const MapsLibrary = useGoogleMapsImportLibrary('maps')
  const markerElements = ref<google.maps.marker.AdvancedMarkerElement[]>([])
  const markerClusterer = ref<MarkerClusterer>()

  watch(
    () =>
      [
        MarkerLibrary.value,
        MapsLibrary.value,
        toValue(map),
        toValue(markers),
        toValue(options),
      ] as const,
    ([MarkerLibrary, MapsLibrary, map, markers, options]) => {
      if (!MarkerLibrary || !MapsLibrary || !map || markers.length === 0) {
        return
      }

      // Clear markers
      for (const marker of markerElements.value) {
        marker.map = null
      }

      // Add markers
      markerElements.value = markers.map((markerOptions) => {
        const pinGlyph = new MarkerLibrary.PinElement({
          background: '#D8FC59',
          borderColor: '#00000033',
          glyphColor: '#00000033',
        })
        const marker = new MarkerLibrary.AdvancedMarkerElement({
          map: options.markerClusterer ? null : map,
          collisionBehavior: markerOptions.collisionBehavior,
          content: pinGlyph.element,
          gmpDraggable: markerOptions.gmpDraggable,
          position: markerOptions.position,
          title: markerOptions.title,
          zIndex: markerOptions.zIndex,
        })

        const infoWindowOptions = options.infoWindow?.({
          marker,
          data: markerOptions.data,
        })

        if (infoWindowOptions) {
          const infoWindow = new MapsLibrary.InfoWindow({
            disableAutoPan: true,
            ...infoWindowOptions,
          })
          marker.addListener('click', () => {
            infoWindow.open({
              anchor: marker,
              map,
            })
          })
        }

        return marker
      })

      if (options.markerClusterer) {
        if (markerClusterer.value) {
          // Update marker clusterer
          markerClusterer.value.clearMarkers()
          markerClusterer.value.setMap(map)
          markerClusterer.value.addMarkers(markerElements.value)
        } else {
          // Create marker clusterer
          markerClusterer.value = markRaw(
            new MarkerClusterer({
              map,
              markers: markerElements.value,
              renderer: {
                render: ({ count, position }, stats) => {
                  const { AdvancedMarkerElement, PinElement } = MarkerLibrary
                  // If we want to change the color if this cluster has more markers than the mean cluster
                  // `count > Math.max(10, stats.clusters.markers.mean)`
                  const color = '#D8FC59'
                  return new AdvancedMarkerElement({
                    position: position,
                    content: createMarkerClustererElement({ color, count }),
                    title: String(count),
                    zIndex: 1000 + count,
                  })
                },
              },
            }),
          )
        }
      } else if (markerClusterer.value) {
        // Clear marker clusterer
        markerClusterer.value.clearMarkers()
        markerClusterer.value.setMap(null)
        markerClusterer.value = undefined
      }

      // automatic zoom to fit markers
      const bounds = new google.maps.LatLngBounds()
      for (const marker of markers) {
        if (marker.position) bounds.extend(marker.position)
      }
      map?.fitBounds(bounds)
    },
    { flush: 'post' },
  )

  return {
    markers: markerElements,
    markerClusterer,
  }
}

function createMarkerClustererElement({
  count,
  color,
}: {
  count: number
  color: string
}) {
  const svg = window.btoa(`
<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="-20 -20 280 280">
  <circle cx="120" cy="120" opacity=".6" r="70" />
  <circle cx="120" cy="120" opacity=".3" r="90" />
  <circle cx="120" cy="120" opacity=".2" r="110" />
  <circle cx="120" cy="120" opacity=".1" r="130" />
</svg>`)

  // Create the icon element
  const el = document.createElement('div')
  el.style.position = 'relative'
  el.style.width = '56px'
  el.style.height = '56px'
  el.innerHTML = `
<img src="data:image/svg+xml;base64,${svg}" style="position: absolute; width: 100%; height: 100%; transform: scale(0.9);" />
<div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; font-size: 12px; font-weight: bold; color: #1A1A1A;">
    ${count}
</div>`

  return el
}
