export enum FieldInputTypes {
  INPUT = 'INPUT',
  PASSWORD = 'PASSWORD',
  TEXT = 'TEXT',
  DATE = 'DATE',
  DROPDOWN = 'DROPDOWN',
  OPTIONS = 'OPTIONS',
  REPEATER = 'REPEATER',
  BUTTON = 'BUTTON',
  CHECKBOX = 'CHECKBOX',
  INCREMENT = 'INCREMENT',
  GOOGLE_ADDRESS = 'GOOGLE_ADDRESS',
}
