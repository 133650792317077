import revive_payload_client_67fT1zNwj2 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@9.11.1_sass@1.79.4_typescript@5.6.2_vite@5.4.7_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_eTUgkwFw9C from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@9.11.1_sass@1.79.4_typescript@5.6.2_vite@5.4.7_vue-tsc@2.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_7XjC3JhAFM from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@9.11.1_sass@1.79.4_typescript@5.6.2_vite@5.4.7_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_6SMp8hEBTj from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@9.11.1_sass@1.79.4_typescript@5.6.2_vite@5.4.7_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_CqzPZ4cMee from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@9.11.1_sass@1.79.4_typescript@5.6.2_vite@5.4.7_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_IEhcAfus1N from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@9.11.1_sass@1.79.4_typescript@5.6.2_vite@5.4.7_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_i64Sh1pbbC from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_typescript@5.6.2_vue@3.5.10/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_wnQjb7ojMY from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@9.11.1_sass@1.79.4_typescript@5.6.2_vite@5.4.7_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/opt/build/repo/.nuxt/formkitPlugin.mjs";
import floating_vue_EIcJ7xiw0h from "/opt/build/repo/.nuxt/floating-vue.mjs";
import plugin_3mDLlUpvJA from "/opt/build/repo/node_modules/.pnpm/nuxt-highcharts@3.1.1/node_modules/nuxt-highcharts/lib/plugin.js";
import plugin_client_1rze2VsG7c from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_XjOJCViDkL from "/opt/build/repo/node_modules/.pnpm/nuxt-supported-browsers@0.0.5/node_modules/nuxt-supported-browsers/dist/runtime/plugin.mjs";
import plugin_Mbtkm8L8cx from "/opt/build/repo/node_modules/.pnpm/mixpanel-nuxt@0.0.13/node_modules/mixpanel-nuxt/dist/runtime/plugin.js";
import plugin_ZXBW6S44aP from "/opt/build/repo/node_modules/.pnpm/dayjs-nuxt@2.1.11/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_wy0B721ODc from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_kb5NVW0Q8L from "/opt/build/repo/layers/shared/modules/sentry/runtime/plugin.ts";
import formkit_autoanimate_itbBXLvUrS from "/opt/build/repo/node_modules/.pnpm/@flnt-accurait+web-components@0.1.22_@types+node@20.16.10_@vueuse+integrations@11.1.0_esbuild_7ugvbwhrzg3urij53vcp73t42a/node_modules/@flnt-accurait/web-components/plugins/formkit-autoanimate.ts";
import tailwind_merge_t8AtflDlAD from "/opt/build/repo/node_modules/.pnpm/@flnt-accurait+web-components@0.1.22_@types+node@20.16.10_@vueuse+integrations@11.1.0_esbuild_7ugvbwhrzg3urij53vcp73t42a/node_modules/@flnt-accurait/web-components/plugins/tailwind-merge.ts";
import vfm_a9xWIwwwWq from "/opt/build/repo/node_modules/.pnpm/@flnt-accurait+web-components@0.1.22_@types+node@20.16.10_@vueuse+integrations@11.1.0_esbuild_7ugvbwhrzg3urij53vcp73t42a/node_modules/@flnt-accurait/web-components/plugins/vfm.ts";
import api_GFfDXud5Cr from "/opt/build/repo/plugins/api.ts";
import auth_vT9JWWT9pN from "/opt/build/repo/plugins/auth.ts";
import casl_QTBkbB6FCs from "/opt/build/repo/plugins/casl.ts";
import companies_gN452sK9TF from "/opt/build/repo/plugins/companies.ts";
import google_maps_sKT4qijSb0 from "/opt/build/repo/plugins/google-maps.ts";
import newrelic_browser_CmZ534RHg2 from "/opt/build/repo/plugins/newrelic-browser.ts";
import toastify_ScRgZgP9op from "/opt/build/repo/plugins/toastify.ts";
import vfm_AT9bKzcFx9 from "/opt/build/repo/plugins/vfm.ts";
import vue_query_wrmMkNpEpe from "/opt/build/repo/plugins/vue-query.ts";
export default [
  revive_payload_client_67fT1zNwj2,
  unhead_eTUgkwFw9C,
  router_7XjC3JhAFM,
  payload_client_6SMp8hEBTj,
  navigation_repaint_client_CqzPZ4cMee,
  chunk_reload_client_IEhcAfus1N,
  plugin_vue3_i64Sh1pbbC,
  components_plugin_KR1HBZs4kY,
  prefetch_client_wnQjb7ojMY,
  formkitPlugin_pZqjah0RUG,
  floating_vue_EIcJ7xiw0h,
  plugin_3mDLlUpvJA,
  plugin_client_1rze2VsG7c,
  plugin_XjOJCViDkL,
  plugin_Mbtkm8L8cx,
  plugin_ZXBW6S44aP,
  plugin_wy0B721ODc,
  plugin_kb5NVW0Q8L,
  formkit_autoanimate_itbBXLvUrS,
  tailwind_merge_t8AtflDlAD,
  vfm_a9xWIwwwWq,
  api_GFfDXud5Cr,
  auth_vT9JWWT9pN,
  casl_QTBkbB6FCs,
  companies_gN452sK9TF,
  google_maps_sKT4qijSb0,
  newrelic_browser_CmZ534RHg2,
  toastify_ScRgZgP9op,
  vfm_AT9bKzcFx9,
  vue_query_wrmMkNpEpe
]