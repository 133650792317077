import { QueryCache } from '@tanstack/vue-query'
import type { AuthUser } from '../types'
import { Roles } from '~/constants/Roles'
import type { Product } from '~/composables/useAPI/types'
import { IOrganizationType } from '~/composables/useAPI/types'

import { useBroadcastChannel } from '@vueuse/core'

export const useAuth = () => {
  const { $updateAbility } = useNuxtApp()
  const { data, status, loading } = useAuthState()
  const { redirect, clearRedirect } = useAuthRedirect()
  const portalAuthCookie = useCookie<string | null>('PortalAuth')
  const isBroadcasting = ref(false)
  const mixpanel = useMixpanel()
  const newRelic = useNewRelic()

  const {
    isSupported,
    post,
    data: dataChannel,
  } = useBroadcastChannel({ name: 'cross-tab-logout' })

  watch(dataChannel, () => {
    if (dataChannel.value === 'logout' && isBroadcasting.value === false) {
      console.count('broadcast logout')
      // BUG: TODO: the broadcast is triggering several times, also, the trigger should not trigger on the same tab, only for other tabs
      isBroadcasting.value = true
      setTimeout(() => {
        signOut()
      }, 2000)
    }
  })

  const signIn = async () => {
    const { redirectUri, clientId, audience, domain } =
      useRuntimeConfig().public.auth0

    const url = new URL(`${domain}/authorize`)
    url.searchParams.set('protocol', 'oauth2')
    url.searchParams.set('response_type', 'code')
    url.searchParams.set('client_id', clientId)
    url.searchParams.set('redirect_uri', redirectUri)
    url.searchParams.set('audience', audience)
    await navigateTo(url.href, { external: true })
  }

  const signOut = async () => {
    const { domain, clientId } = useRuntimeConfig().public.auth0
    const baseURL = useRuntimeConfig().public.baseURL

    // clear cache
    clearQueryCache()

    // delete last division cache
    const lastDivision = useCookie('last-division')
    lastDivision.value = undefined

    portalAuthCookie.value = null
    data.value = undefined
    loading.value = false

    mixpanel.reset()
    newRelic.logout()

    // make a request to log out user from auth0
    const url = new URL(`${domain}/v2/logout`)
    url.searchParams.set('client_id', clientId)

    // If we have a redirect url, we need to add it to `returnTo` param
    if (
      redirect.value?.url &&
      redirect.value.url.startsWith('/account/invitation')
    ) {
      url.searchParams.set('returnTo', baseURL + redirect.value.url)

      // Otherwise, if we are in development environment, return to register page
    } else if (useRuntimeConfig().public.ENV === 'local') {
      url.searchParams.set('returnTo', baseURL + '/auth/register')
    }

    clearRedirect()

    // signout from api
    return fetchAPI('/me/signout', {
      method: 'POST',
    })
      .then(async () => {
        // ability.value = undefined
        await navigateTo(url.href, { external: true })
        if (isSupported) {
          post('logout')
        }
      })
      .catch((e) => {
        console.error('Error to logout', e)
      })
  }
  const fetchUser = async () => {
    loading.value = true
    const res = await fetchAPI<AuthUser>('/me').catch(() => null)
    // await updatePermissions(res)
    $updateAbility(res)
    loading.value = false
    data.value = res

    mixpanel.identify(res?.id)
    mixpanel.people?.set({
      $name: res?.name,
      $email: res?.email,
      Organization: res?.organization,
      Permissions: res?.permissions,
      Products: res?.products,
      isVerified: res?.isVerified,
    })

    newRelic.login(data.value)
    newRelic.log('User logged in')
  }

  const isMe = (id: string) => data.value?.id === id

  const role = computed(() => {
    const roleId = data.value?.permissions?.[0]?.roleId
    const role = Object.values(Roles).find((role) => role.id === roleId)
    return role ?? Roles.Unknown
  })

  const isTenant = computed(
    () => data.value?.organization.type === IOrganizationType.TENANT,
  )
  const isLandlord = computed(
    () => data.value?.organization.type === IOrganizationType.LANDLORD,
  )

  const hasProduct = (product: Product) => {
    return !!data.value?.products?.includes(product)
  }

  return {
    data,
    status,
    role,
    isTenant,
    isLandlord,
    signIn,
    signOut,
    fetchUser,
    isMe,
    hasProduct,
  }
}

function clearQueryCache() {
  const queryCache = new QueryCache()
  queryCache.clear()
}
