import { nanoid } from 'nanoid'
import type { FetchOptions } from 'ofetch'
import type { $Fetch } from 'nitropack'
import { AuthRedirectCondition } from '@authentication'

export default defineNuxtPlugin({
  name: 'api',
  dependsOn: ['newrelic-browser'],
  setup() {
    const { apiURL, managerURL, auditURL, apvcURL } = useRuntimeConfig().public
    const route = useRoute()
    const { status, signIn } = useAuth()
    const { setRedirect } = useAuthRedirect()
    const newRelic = useNewRelic()

    const initHeaders = (options: FetchOptions) => {
      const headers = (options.headers ||= {})
      const setHeader = (name: string, value: string) => {
        if (Array.isArray(headers)) {
          headers.push([name, value])
        } else if (headers instanceof Headers) {
          headers.set(name, value)
        } else {
          headers[name] = value
        }
      }

      return { setHeader }
    }

    const createApi = (url: string, credentials?: RequestCredentials) =>
      $fetch.create({
        baseURL: url,
        credentials,
        onRequest: ({ options }) => {
          const { setHeader } = initHeaders(options)
          setHeader('Correlation-id', nanoid())
        },
        onResponseError: async (ctx) => {
          if (ctx.error) newRelic.setError(ctx.error)

          if (status.value === 'authenticated' && ctx.response.status === 401) {
            // remember destination and redirect to login
            setRedirect({
              url: route.fullPath,
              condition: AuthRedirectCondition.Authenticated,
            })

            await signIn()
            return
          }

          throw createApiError(ctx)
        },
        retry: false, // vue-query handles the retry
      })

    const api = createApi(apiURL, 'include')
    const apiManager = createApi(managerURL, 'include')
    const apiAudit = createApi(auditURL)
    const apiAPVC = createApi(apvcURL)

    // Expose to useNuxtApp().$api
    return {
      provide: {
        api,
        apiManager,
        apiAudit,
        apiAPVC,
      },
    }
  },
})

declare module '#app' {
  interface NuxtApp {
    $api: $Fetch
    $apiManager: $Fetch
    $apiAudit: $Fetch
    $apiAPVC: $Fetch
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $api: $Fetch
    $apiManager: $Fetch
    $apiAudit: $Fetch
    $apiAPVC: $Fetch
  }
}
