export default {
'accessibility': () => import('highcharts/modules/accessibility.js'),
'annotations-advanced': () => import('highcharts/modules/annotations-advanced.js'),
'annotations': () => import('highcharts/modules/annotations.js'),
'arc-diagram': () => import('highcharts/modules/arc-diagram.js'),
'arrow-symbols': () => import('highcharts/modules/arrow-symbols.js'),
'boost-canvas': () => import('highcharts/modules/boost-canvas.js'),
'boost': () => import('highcharts/modules/boost.js'),
'broken-axis': () => import('highcharts/modules/broken-axis.js'),
'bullet': () => import('highcharts/modules/bullet.js'),
'coloraxis': () => import('highcharts/modules/coloraxis.js'),
'current-date-indicator': () => import('highcharts/modules/current-date-indicator.js'),
'cylinder': () => import('highcharts/modules/cylinder.js'),
'data-tools': () => import('highcharts/modules/data-tools.js'),
'data': () => import('highcharts/modules/data.js'),
'datagrouping': () => import('highcharts/modules/datagrouping.js'),
'debugger': () => import('highcharts/modules/debugger.js'),
'dependency-wheel': () => import('highcharts/modules/dependency-wheel.js'),
'dotplot': () => import('highcharts/modules/dotplot.js'),
'drag-panes': () => import('highcharts/modules/drag-panes.js'),
'draggable-points': () => import('highcharts/modules/draggable-points.js'),
'drilldown': () => import('highcharts/modules/drilldown.js'),
'dumbbell': () => import('highcharts/modules/dumbbell.js'),
'export-data': () => import('highcharts/modules/export-data.js'),
'exporting': () => import('highcharts/modules/exporting.js'),
'flowmap': () => import('highcharts/modules/flowmap.js'),
'full-screen': () => import('highcharts/modules/full-screen.js'),
'funnel': () => import('highcharts/modules/funnel.js'),
'funnel3d': () => import('highcharts/modules/funnel3d.js'),
'gantt': () => import('highcharts/modules/gantt.js'),
'geoheatmap': () => import('highcharts/modules/geoheatmap.js'),
'grid-axis': () => import('highcharts/modules/grid-axis.js'),
'heatmap': () => import('highcharts/modules/heatmap.js'),
'heikinashi': () => import('highcharts/modules/heikinashi.js'),
'histogram-bellcurve': () => import('highcharts/modules/histogram-bellcurve.js'),
'hollowcandlestick': () => import('highcharts/modules/hollowcandlestick.js'),
'item-series': () => import('highcharts/modules/item-series.js'),
'lollipop': () => import('highcharts/modules/lollipop.js'),
'map': () => import('highcharts/modules/map.js'),
'marker-clusters': () => import('highcharts/modules/marker-clusters.js'),
'mouse-wheel-zoom': () => import('highcharts/modules/mouse-wheel-zoom.js'),
'navigator': () => import('highcharts/modules/navigator.js'),
'networkgraph': () => import('highcharts/modules/networkgraph.js'),
'no-data-to-display': () => import('highcharts/modules/no-data-to-display.js'),
'offline-exporting': () => import('highcharts/modules/offline-exporting.js'),
'organization': () => import('highcharts/modules/organization.js'),
'overlapping-datalabels': () => import('highcharts/modules/overlapping-datalabels.js'),
'parallel-coordinates': () => import('highcharts/modules/parallel-coordinates.js'),
'pareto': () => import('highcharts/modules/pareto.js'),
'pathfinder': () => import('highcharts/modules/pathfinder.js'),
'pattern-fill': () => import('highcharts/modules/pattern-fill.js'),
'pictorial': () => import('highcharts/modules/pictorial.js'),
'price-indicator': () => import('highcharts/modules/price-indicator.js'),
'pyramid3d': () => import('highcharts/modules/pyramid3d.js'),
'sankey': () => import('highcharts/modules/sankey.js'),
'series-label': () => import('highcharts/modules/series-label.js'),
'series-on-point': () => import('highcharts/modules/series-on-point.js'),
'solid-gauge': () => import('highcharts/modules/solid-gauge.js'),
'sonification': () => import('highcharts/modules/sonification.js'),
'static-scale': () => import('highcharts/modules/static-scale.js'),
'stock-tools': () => import('highcharts/modules/stock-tools.js'),
'stock': () => import('highcharts/modules/stock.js'),
'streamgraph': () => import('highcharts/modules/streamgraph.js'),
'sunburst': () => import('highcharts/modules/sunburst.js'),
'textpath': () => import('highcharts/modules/textpath.js'),
'tiledwebmap': () => import('highcharts/modules/tiledwebmap.js'),
'tilemap': () => import('highcharts/modules/tilemap.js'),
'timeline': () => import('highcharts/modules/timeline.js'),
'treegraph': () => import('highcharts/modules/treegraph.js'),
'treegrid': () => import('highcharts/modules/treegrid.js'),
'treemap': () => import('highcharts/modules/treemap.js'),
'variable-pie': () => import('highcharts/modules/variable-pie.js'),
'variwide': () => import('highcharts/modules/variwide.js'),
'vector': () => import('highcharts/modules/vector.js'),
'venn': () => import('highcharts/modules/venn.js'),
'windbarb': () => import('highcharts/modules/windbarb.js'),
'wordcloud': () => import('highcharts/modules/wordcloud.js'),
'xrange': () => import('highcharts/modules/xrange.js'),
}