import type {
  DehydratedState,
  VueQueryPluginOptions,
} from '@tanstack/vue-query'
import {
  VueQueryPlugin,
  QueryClient,
  hydrate,
  dehydrate,
} from '@tanstack/vue-query'
import { ApiError } from '@shared'

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
const retryStatusCodes = new Set([
  408, // Request Timeout
  409, // Conflict
  425, // Too Early (Experimental)
  429, // Too Many Requests
  500, // Internal Server Error
  502, // Bad Gateway
  503, // Service Unavailable
  504, // Gateway Timeout
])

export default defineNuxtPlugin({
  name: 'vue-query',
  setup(nuxtApp) {
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          // @ts-expect-error - `shallow` was added in v5.54.2, but for some reason it's not in the types.
          // We must set it to `true` in order to avoid differences between dev and prod.
          shallow: true,
          gcTime: 1000 * 60 * 60 * 24 * 7, // 24 hours
          staleTime: 1000 * 30, // 30 seconds
          refetchOnWindowFocus: false,
          retry: (failureCount, error) => {
            // Only retry for status codes that are in the list
            if (
              error instanceof ApiError &&
              retryStatusCodes.has(error.statusCode || 500)
            )
              return failureCount < 3
            return false
          },
          retryDelay: (attemptIndex: number) =>
            Math.min(1000 * 2 ** attemptIndex, 30000),
        },
      },
    })

    const pluginOptions: VueQueryPluginOptions = {
      queryClient,
      enableDevtoolsV6Plugin: true,
    }

    nuxtApp.vueApp.use(VueQueryPlugin, pluginOptions)
    const vueQueryClient = useState<DehydratedState | null>('vue-query')

    if (import.meta.server) {
      nuxtApp.hooks.hook('app:rendered', () => {
        vueQueryClient.value = dehydrate(queryClient)
      })
    }

    if (import.meta.client) {
      nuxtApp.hooks.hook('app:created', () => {
        hydrate(queryClient, vueQueryClient.value)
      })
    }
  },
})

declare module '@tanstack/vue-query' {
  interface Register {
    defaultError: ApiError
  }
}
