<script setup lang="ts">
import { Product } from '~/composables/useAPI/types'
interface Props {
  toggleable?: boolean
}
withDefaults(defineProps<Props>(), {
  toggleable: false,
})
const { currentDivision, createDivisionURL } = useDivisions()
</script>

<template>
  <div class="fixed left-0 right-0 z-[900] print:hidden">
    <div
      class="pt-safe-top bg-gray-900/60 bg-opacity-80 backdrop-blur-2xl backdrop-saturate-[180%]"
    >
      <nav class="h-navigation flex items-center justify-between px-6">
        <NavigationDesktop />
      </nav>
    </div>
  </div>

  <!-- MOBILE FIXED BOTTOM -->
  <transition
    enter-active-class="transition duration-200 ease-out"
    enter-from-class="translate-y-4 opacity-0"
    enter-to-class="translate-y-0 opacity-100 "
    leave-active-class="transition duration-150 ease-in"
    leave-from-class="translate-y-0 opacity-100"
    leave-to-class="translate-y-1 opacity-0 "
  >
    <div
      v-if="currentDivision"
      class="pb-safe-bottom fixed bottom-0 left-0 z-[100] flex w-full grow flex-row bg-gray-900/60 bg-opacity-80 backdrop-blur-2xl backdrop-saturate-[180%] lg:hidden"
    >
      <NavigationButtonIcon
        :to="createDivisionURL()"
        icon="home"
        class="flex-auto"
      />

      <NavigationButtonIcon
        :to="createDivisionURL('workflow')"
        icon="document-do-list"
        class="flex-auto"
      />

      <NavigationButtonIcon
        :to="createDivisionURL('portfolio')"
        icon="suitcase-portfolio"
        class="flex-auto"
      />

      <NavigationButtonIcon
        v-if="$can('product', Product.CRITICAL_DATES)"
        :to="createDivisionURL('critical-dates')"
        icon="calendar"
        class="flex-auto"
      />

      <NavigationButtonIcon
        :to="`/division/${currentDivision.id}/reports`"
        icon="mailbox-download"
        class="flex-auto"
      />
      <NavigationButtonIcon
        v-if="$can('product', Product.AUDIT)"
        :to="`/division/${currentDivision.id}/audit`"
        icon="calendar-schedule-time"
        class="flex-auto"
      />
      <NavigationButtonIcon
        :to="`/division/${currentDivision.id}/apvc`"
        icon="Cashflow"
        class="flex-auto"
      />
    </div>
  </transition>
</template>
